import React from 'react';
import Participant from '../Participant/Participant';
import { createStyles, makeStyles, styled, Theme } from '@material-ui/core/styles';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import useRoomState from '../../hooks/useRoomState/useRoomState';

const Container = styled('aside')(({ theme }) => ({}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    style1: {
      position: 'absolute',
      bottom: 0,
      right: `calc(100% - ${theme.sidebarWidth}px)`,
      left: 0,
      padding: '0.5em',
      overflowY: 'auto',
    },
    style2: {
      flexGrow: 1,
      overflowY: 'auto',
      paddingBottom: '10px',
    },
  })
);

export default function ParticipantStrip() {
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const classes = useStyles();
  const [width, height] = useWindowSize();

  const roomState = useRoomState();
  const menuWillHide = roomState !== 'disconnected' && !(width > 500 && height > 500);

  return (
    <Container
      style={{ top: menuWillHide ? '0' : '64px' }}
      className={`${width > 500 && width > height ? classes.style1 : classes.style2}`}
    >
      <Participant
        participant={localParticipant}
        isSelected={selectedParticipant === localParticipant}
        onClick={() => setSelectedParticipant(localParticipant)}
      />
      {participants.map(participant => (
        <Participant
          key={participant.sid}
          participant={participant}
          isSelected={selectedParticipant === participant}
          onClick={() => setSelectedParticipant(participant)}
        />
      ))}
    </Container>
  );
}
