import React from 'react';
import { styled } from '@material-ui/core/styles';

import Controls from './components/Controls/Controls';
import LocalVideoPreview from './components/LocalVideoPreview/LocalVideoPreview';
import MenuBar from './components/MenuBar/MenuBar';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';

import useRoomState from './hooks/useRoomState/useRoomState';
import useWindowSize from './hooks/useWindowSize/useWindowSize';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
});

const Main = styled('main')({
  height: '100%',
  position: 'relative',
  paddingTop: '64px',
});

const PoweredBy = styled('div')({
  position: 'absolute',
  bottom: '5px',
  right: '5px',
});

export default function App() {
  const roomState = useRoomState();

  const [width, height] = useWindowSize();
  const menuWillHide = roomState !== 'disconnected' && !(width > 500 && height > 500);

  window.onbeforeunload = function(e: BeforeUnloadEvent) {
    e.preventDefault();
    e.stopPropagation();
    return roomState === 'connected';
  };

  return (
    <Container>
      <MenuBar />
      <Main style={{ paddingTop: menuWillHide ? '5px' : '64px' }}>
        {roomState === 'disconnected' ? <LocalVideoPreview /> : <Room />}
        <Controls />
        <PoweredBy>Powered by BestNotes</PoweredBy>
      </Main>
      <ReconnectingNotification />
    </Container>
  );
}
