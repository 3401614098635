import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

export default function useRoomState() {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const setSize = debounce(
      () => {
        setDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      },
      200,
      { maxWait: 100 }
    );

    window.addEventListener('resize', setSize);
    window.addEventListener('orientationchange', setSize);
    return () => {
      window.removeEventListener('resize', setSize);
      window.removeEventListener('orientationchange', setSize);
    };
  });

  return [dimensions.width, dimensions.height] as const;
}
