import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import ToggleFullscreenButton from '../ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from './Menu/Menu';

import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography } from '@material-ui/core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import useIsUserActive from '../Controls/useIsUserActive/useIsUserActive';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      position: 'fixed',
      transform: 'translate(0, -30px)',
      zIndex: 5,
      transition: 'opacity 1.2s, transform 1.2s, visibility 0s 1.2s',
      opacity: 0,
      visibility: 'hidden',
      '&.showMenu, &:hover': {
        transition: 'opacity 0.6s, transform 0.6s, visibility 0s',
        opacity: 1,
        visibility: 'visible',
        transform: 'translate(0, 0px)',
      },
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 180,
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    displayName: {
      marginLeft: '2.2em',
      minWidth: '200px',
      fontWeight: 600,
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { URLRoomName } = useParams();
  const { user, getToken } = useAppState();
  const { isConnecting, connect } = useVideoContext();
  const roomState = useRoomState();

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [width, height] = useWindowSize();
  const [tryingToConnect, setTryingToConnect] = useState(false);
  const isUserActive = useIsUserActive();
  const showMenu = isUserActive || roomState === 'disconnected' || (width > 500 && height > 500);

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
    }
  }, [URLRoomName]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setTryingToConnect(true);
    // If this app is deployed as a twilio function, don't change the URL beacuse routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}`));
    }
    getToken(name, roomName).then(token => connect(token));
    setTryingToConnect(false);
  };

  return (
    <AppBar className={clsx(classes.container, { showMenu })} position="fixed">
      <Toolbar>
        {roomState === 'disconnected' ? (
          <form className={classes.form} onSubmit={handleSubmit}>
            <div>
              {!user?.displayName ? (
                <TextField
                  id="menu-name"
                  label="Your Name"
                  helperText="Enter your name here"
                  className={classes.textField}
                  value={name}
                  onChange={handleNameChange}
                  margin="dense"
                />
              ) : (
                <Typography className={classes.displayName} variant="body1">
                  {user.displayName}
                </Typography>
              )}
              {/*{roomName ? (*/}
              {/*  <div>*/}
              {/*    <b>Room:</b> {roomName}*/}
              {/*  </div>*/}
              {/*) : (*/}
              {/*  ''*/}
              {/*)}*/}
            </div>
            {/*<TextField*/}
            {/*  id="menu-room"*/}
            {/*  label="Room"*/}
            {/*  className={classes.textField}*/}
            {/*  value={roomName}*/}
            {/*  onChange={handleRoomNameChange}*/}
            {/*  margin="dense"*/}
            {/*/>*/}
            <div>
              {isConnecting ? (
                <CircularProgress className={classes.loadingSpinner} />
              ) : (
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={tryingToConnect || isConnecting || !name || !roomName}
                >
                  Join Session
                </Button>
              )}
            </div>
          </form>
        ) : (
          <h3>Telehealth Meeting</h3>
        )}
        {(width > 425 || roomState === 'connected') && <ToggleFullscreenButton />}
        {/*<Menu />*/}
      </Toolbar>
    </AppBar>
  );
}
