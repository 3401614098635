import React, { useEffect, useState } from 'react';
import ParticipantStrip from '../ParticipantStrip/ParticipantStrip';
import { createStyles, makeStyles, styled, Theme } from '@material-ui/core/styles';
import MainParticipant from '../MainParticipant/MainParticipant';

import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import useIsUserActive from '../Controls/useIsUserActive/useIsUserActive';
import useRoomState from '../../hooks/useRoomState/useRoomState';

const Container = styled('div')({
  display: 'flex',
  height: '100%',
  flexDirection: 'column-reverse',
});

const MainParticipantContainer = styled('div')(({ theme }) => ({}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainParticipantContainer: {
      position: 'absolute',
      left: theme.sidebarWidth,
      right: 0,
      bottom: 0,
      '& > div': {
        height: '100%',
      },
    },
    mainParticipantContainerMobile: {
      marginBottom: '1em',
      maxHeight: '55vh',
    },
  })
);

export default function Room() {
  const classes = useStyles();

  const [width, height] = useWindowSize();
  const roomState = useRoomState();
  const menuWillHide = roomState !== 'disconnected' && !(width > 500 && height > 500);

  return (
    <Container>
      <ParticipantStrip />
      <MainParticipantContainer
        style={{ top: menuWillHide ? '0' : '64px' }}
        className={`${
          width > 500 && width > height ? classes.mainParticipantContainer : classes.mainParticipantContainerMobile
        }`}
      >
        <MainParticipant />
      </MainParticipantContainer>
    </Container>
  );
}
